// Autogenerated using mud system-types
import ComponentDevSystem from "../abi/ComponentDevSystem.json";
import InitSystem from "../abi/InitSystem.json";
import PaintSystem from "../abi/PaintSystem.json";
import PauseSystem from "../abi/PauseSystem.json";
import TickSystem from "../abi/TickSystem.json";

export const SystemAbis = {
  "conway.system.componentDev": ComponentDevSystem.abi,
  "conway.system.init": InitSystem.abi,
  "conway.system.paint": PaintSystem.abi,
  "conway.system.pause": PauseSystem.abi,
  "conway.system.tick": TickSystem.abi,
};
